// localisation //
import {createI18n} from 'vue-i18n';
import {messages, defaultLocale} from "@/locales";
import utilsService from "@/core/services/utilsService";

function checkDefaultLanguage() {

    // try to get value from local storage //
    const userLanguage = utilsService.getLocalStorage('user-language');
    if (userLanguage) {
        return userLanguage;
    }

    const supportedLanguages = Object.getOwnPropertyNames(messages);
    let matched = supportedLanguages.find(lang => lang === navigator.language);

    if (!matched) {
        const navigatorLanguagePartials = navigator.language.split('-')[0];
        matched = supportedLanguages.find(lang => lang === navigatorLanguagePartials);
    }

    if (!matched) {
        const navigatorLanguagePartials = navigator.language.split('-')[0];
        matched = supportedLanguages.find(lang => lang.split('-')[0] === navigatorLanguagePartials);
    }

    if (!matched) {
        return 'en';
    }

    return matched;
}

export default createI18n({
    legacy: false, // you must set `false`, to use Composition API
    locale: checkDefaultLanguage(),
    fallbackLocale: defaultLocale,
    messages,
});
