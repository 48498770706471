
import {defineComponent, onUpdated, provide, ref, watch} from "vue";
import {useI18n} from 'vue-i18n';
import utilsService from "@/core/services/utilsService";
import store from "@/store";
import {Actions} from "@/store/enums/store.enums";

// ENV VARS //
const theme = process.env.VUE_APP_THEME;

export default defineComponent({
  name: "vo-desk",
  setup() {

    // locale as key to force rerender ALL router views //
    const {locale} = useI18n({useScope: 'global'});
    let selectedLanguage = locale.value;

    // create global provider //
    //provide('translate', translate);

    // set theme to body class //
    store.dispatch(Actions.ADD_BODY_CLASSNAME, theme);

    onUpdated(() => {

      // remember new select value in local storage //
      if (selectedLanguage !== locale.value) {

        selectedLanguage = locale.value;
        utilsService.setLocalStorage('user-language', selectedLanguage);

      }

    });

    return {
      locale
    }

  }

});
