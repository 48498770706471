import { locales } from "./locales";

import en from "./en.json";
import fr from "./fr.json";
import es from "./es.json";
import it from "./it.json";
import de from "./de.json";

export const messages = {
  [locales.EN]: en,
  [locales.FR]: fr,
  [locales.DE]: de,
  [locales.ES]: es,
  [locales.IT]: it,
};

export const defaultLocale = locales.EN;