import apiService from "@/core/services/apiService";

export type State = { classes: object };
import {Module, VuexModule, Mutation, Action} from "vuex-module-decorators";
import {Mutations} from "@/store/enums/store.enums";
import {ReceivingActions, ReceivingMutation} from "@/modules/receiving/store/enums";

@Module
export default class Store extends VuexModule {
    renderKey = 1;

    listParams = {
        page: 1,
        results: 10,
        search: null,
        sort: [],
        filter: {}
    };

    listCache = [];
    listTotal = 0;
    listFilterOptions = {};

    optionsTransportationTypes = [
        {value: 'sea', label: 'Sea'},
        {value: 'air', label: 'Air'},
        {value: 'truck', label: 'Truck'},
    ];

    optionsAirPackageTypes = [
        {value: 'cartons', label: 'Cartons'},
        {value: 'pallets', label: 'Pallets'}
    ];

    optionsTruckLoadTypes = [
        {value: 'ftl', label: 'Full Truck Load (FTL)'},
        {value: 'ltl', label: 'Less than Truckload (LTL)'}
    ];

    optionsTruckTrailerTypes = [
        {value: '26', label: '26ft trailer'},
        {value: '48', label: '48ft trailer'},
        {value: '53', label: '53ft trailer'}
    ];

    optionsSeaLoadTypes = [
        {value: 'fcl', label: 'Full Container Load (FCL)'},
        {value: 'lcl', label: 'Less than Container (LCL)'}
    ];

    optionsSeaPackageTypes = [
        {value: 'loose', label: 'Loose Loaded'},
        {value: 'pallet', label: 'Pallet Loaded'}
    ];

    optionsTruckPackageTypes = [
        {value: 'floor', label: 'Floor Loaded'},
        {value: 'pallet', label: 'Pallet Loaded'}
    ];

    optionsSeaContainerTypes = [
        {value: '20', label: '20ft'},
        {value: '40', label: '40ft'},
        {value: '40hc', label: '40ft High Cube (HC)'},
        {value: '45hc', label: '45ft High Cube (HC)'}
    ];

    optionsPalletTypes = [
        {value: 'eu1', label: 'EU1 (800x1200mm)'},
        {value: 'eu2', label: 'EU2 (1000x1200mm)'},
        {value: 'us1', label: 'US (48”x40” GMA)'},
        {value: 'us2', label: 'US (42”x42”)'},
        {value: 'us3', label: 'US (48”x48”)'},
        {value: 'custom', label: 'CUSTOM'}
    ];

    optionsPalletMaterial = [
        {value: 'wood', label: 'Wood'},
        {value: 'plastic', label: 'Plastic'},
        {value: 'cardboard', label: 'Cardboard'}
    ];

    optionsConfirmLostTypes = [
        {value: 'damaged', label: 'Damaged'},
        {value: 'lost', label: 'Lost'},
    ];

    get getReceivingRenderKey() {
        return 'receiving-' + this.renderKey;
    }

    get getReceivingLastListResult() {
        return this.listCache;
    }

    get getReceivingLastFilterOptions() {
        return this.listFilterOptions;
    }

    get getReceivingsLastListTotal() {
        return this.listTotal;
    }

    get getOptionsTransportationTypes() {
        return this.optionsTransportationTypes;
    }

    get getOptionsAirPackageTypes() {
        return this.optionsAirPackageTypes;
    }

    get getOptionsTruckLoadTypes() {
        return this.optionsTruckLoadTypes;
    }

    get getOptionsTruckTrailerTypes() {
        return this.optionsTruckTrailerTypes;
    }

    get getOptionsSeaLoadTypes() {
        return this.optionsSeaLoadTypes;
    }

    get getOptionsSeaContainerTypes() {
        return this.optionsSeaContainerTypes;
    }

    get getOptionsSeaPackageTypes() {
        return this.optionsSeaPackageTypes;
    }

    get getOptionsTruckPackageTypes() {
        return this.optionsTruckPackageTypes;
    }

    get getOptionsPalletTypes() {
        return this.optionsPalletTypes;
    }

    get getOptionsPalletMaterials() {
        return this.optionsPalletMaterial;
    }

    get getOptionsConfirmLostTypes() {
        return this.optionsConfirmLostTypes;
    }

    @Mutation
    [ReceivingMutation.SET_RENDER_UPDATE]() {
        this.renderKey++;
    }

    @Mutation
    [ReceivingMutation.SET_LIST_RESULT]({listParams, listTotal, listCache, listFilterOptions}) {
        this.listParams = listParams;
        this.listCache = listCache;
        this.listFilterOptions = listFilterOptions;
        this.listTotal = listTotal;
    }

    @Action
    [ReceivingActions.REFRESH_LIST]() {
        this.context.dispatch(ReceivingActions.FETCH_LIST, this.listParams);
    }

    @Action
    [ReceivingActions.FETCH_LIST](params) {

        return new Promise<void>((resolve, reject) => {

            apiService.post("receiving/list", params).then((response) => {

                const data = response.data || null;

                const _verified = apiService.verifyApiResult(data);
                if (!_verified) {
                    this.context.commit(Mutations.SET_ERROR, data);
                    return reject();
                }

                // return results into storage for reactive update if needed //
                this.context.commit(ReceivingMutation.SET_LIST_RESULT, {
                    listParams: params,
                    listTotal: data.results,
                    listCache: data.result,
                    listFilterOptions: data.filter
                });
                resolve();

            }).catch((response) => {

                this.context.commit(Mutations.SET_ERROR, response);
                reject();

            });

        });

    };

    @Action
    [ReceivingActions.GET_ITEM](detailId) {

        return new Promise<void>((resolve, reject) => {

            apiService.get(`receiving/get/${detailId}`).then(({data}) => {

                const _verified = apiService.verifyApiResult(data);
                if (!_verified) {
                    this.context.commit(Mutations.SET_ERROR, data);
                    return reject();
                }

                // return results to dispatcher without mutation //
                resolve(data.result);

            }).catch((response) => {

                this.context.commit(Mutations.SET_ERROR, response);
                reject();

            });

        });

    };

    @Action
    [ReceivingActions.CREATE_ITEM](payload) {

        return new Promise((resolve, reject) => {

            const {data} = payload;

            apiService.post('receiving/create/', data).then(({data}) => {

                const _verified = apiService.verifyApiResult(data);
                if (!_verified) {
                    this.context.commit(Mutations.SET_ERROR, data);
                    return reject();
                }

                // flag cached keep alive comp to render new //
                // disabled because of wizard needs to stay //
                // this.context.commit(ReceivingMutation.SET_RENDER_UPDATE);

                // refresh list in background//
                this.context.dispatch(ReceivingActions.REFRESH_LIST);

                // return results to dispatcher without mutation //
                resolve(data.result);

            }).catch((response) => {

                this.context.commit(Mutations.SET_ERROR, response);
                reject();

            });

        });

    };

    @Action
    [ReceivingActions.UPDATE_ITEM](payload) {

        return new Promise((resolve, reject) => {

            const {editId, data} = payload;

            apiService.put(`receiving/edit/${editId}`, data).then(({data}) => {

                const _verified = apiService.verifyApiResult(data);
                if (!_verified) {
                    this.context.commit(Mutations.SET_ERROR, data);
                    return reject();
                }

                // refresh list in background//
                this.context.dispatch(ReceivingActions.REFRESH_LIST);

                // flag cached keep alive comp to render new //
                // @ToDo Notwendig??
                //this.context.commit(ReceivingMutation.SET_RENDER_UPDATE);

                // return results to dispatcher without mutation //
                resolve(data.result);

            }).catch((response) => {

                this.context.commit(Mutations.SET_ERROR, response);
                reject();

            });

        });

    };

    @Action
    [ReceivingActions.CONFIRM_RECEIPT](payload) {

        return new Promise<void>((resolve, reject) => {

            const {editId, data} = payload;

            apiService.put(`receiving/confirm/${editId}`, data).then(({data}) => {

                const _verified = apiService.verifyApiResult(data);
                if (!_verified) {
                    this.context.commit(Mutations.SET_ERROR, data);
                    return reject();
                }

                // refresh list in background//
                this.context.dispatch(ReceivingActions.REFRESH_LIST);

                // return results to dispatcher without mutation //
                resolve();

            }).catch((response) => {

                this.context.commit(Mutations.SET_ERROR, response);
                reject();

            });

        });

    };

    @Action
    [ReceivingActions.DELETE_ITEM](editId) {

        return new Promise((resolve, reject) => {

            apiService.delete(`receiving/delete/${editId}`).then(({data}) => {

                const _verified = apiService.verifyApiResult(data);
                if (!_verified) {
                    this.context.commit(Mutations.SET_ERROR, data);
                    return reject();
                }

                // refresh list in background//
                this.context.dispatch(ReceivingActions.REFRESH_LIST);

                // return results to dispatcher without mutation //
                resolve(data.result);

            }).catch((response) => {

                this.context.commit(Mutations.SET_ERROR, response);
                reject();

            });

        });

    };

    @Action
    [ReceivingActions.UPLOAD_DATA](payload) {

        return new Promise<void>((resolve, reject) => {

            const {editId, files} = payload;

            apiService.post(`receiving/upload/${editId}`, files).then((response) => {

                const data = response.data || null;

                const _verified = apiService.verifyApiResult(data);
                if (!_verified) {
                    this.context.commit(Mutations.SET_ERROR, data);
                    return reject();
                }

                resolve();

            }).catch((response) => {

                this.context.commit(Mutations.SET_ERROR, response);
                reject();

            });

        });

    };

}
