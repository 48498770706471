enum CustomersActions {
    FETCH_LIST = "customersFetchList",
    GET_ITEM = "customersGetItem",
    CREATE_ITEM = "customersCreateItem",
    UPDATE_ITEM = "customersUpdateItem",
    UPDATE_RIGHTS = "customersUpdateRights",
    DELETE_ITEM = "customersDeleteItem",
    REFRESH_LIST = "customersRefreshList",
    UPDATE_ALLOCATIONS = "customersUpdateAllocations",
    IMPORT_CUSTOMERS = "customersImport",
}

enum CustomersMutation {
    SET_RENDER_UPDATE = "customersListRenderNeeded",
    SET_LIST_RESULT = "customersListResult",
    SET_CURRENT_CUSTOMER = "customersSetItem"
}

export {CustomersActions, CustomersMutation};