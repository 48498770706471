import apiService from "@/core/services/apiService";

export type State = { classes: object };
import {Module, VuexModule, Mutation, Action} from "vuex-module-decorators";
import {Mutations} from "@/store/enums/store.enums";
import {DashboardMutation, DashboardActions} from "@/modules/dashboard/store/enums";

@Module
export default class Store extends VuexModule {

    renderKey = 1;
    stats = {};

    get getDashboardRenderKey() {
        return 'dashboard-' + this.renderKey;
    }

    get getDashboardReceivingStats() {
        return this.stats['receiving'] ?? [];
    }

    get getDashboardOrderStats() {
        return this.stats['orders'] ?? [];
    }

    @Mutation
    [DashboardMutation.SET_RENDER_UPDATE]() {
        this.renderKey++;
    }

    @Mutation
    [DashboardMutation.SET_STATS]({module, data}) {
        this.stats[module] = data;
    }

    @Action
    [DashboardActions.GET_STATS](module) {

        return new Promise<void>((resolve, reject) => {

            apiService.get(`${module}/stats`).then((response) => {

                const data = response.data || null;

                const _verified = apiService.verifyApiResult(data);
                if (!_verified) {
                    this.context.commit(Mutations.SET_ERROR, data);
                    return reject();
                }

                this.context.commit(DashboardMutation.SET_STATS, {
                    module: module,
                    data: data.result,
                });

                resolve();

            }).catch((response) => {

                this.context.commit(Mutations.SET_ERROR, response);
                reject();

            });

        });

    };

}
