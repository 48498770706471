const ID_TOKEN_KEY = "vo-storage-desk-token" as string;
const ID_TOKEN_REFRESH_KEY = "vo-storage-desk-token-refresh" as string;

const jwtService = {
    saveToken: async function (token: string) {
        await null;
        return window.localStorage.setItem(ID_TOKEN_KEY, token);
    },
    saveRefreshToken: async function (token: string) {
        await null;
        return window.localStorage.setItem(ID_TOKEN_REFRESH_KEY, token);
    },
    getToken: async function () {
        await null;
        return window.localStorage.getItem(ID_TOKEN_KEY);
    },
    getRefreshToken: async function () {
        await null;
        return window.localStorage.getItem(ID_TOKEN_REFRESH_KEY);
    },
    destroyToken: async function () {
        await null;
        window.localStorage.removeItem(ID_TOKEN_KEY);
    },
    destroyRefreshToken: async function () {
        await null;
        window.localStorage.removeItem(ID_TOKEN_REFRESH_KEY);
    }
};

export default jwtService;