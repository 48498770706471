import {createApp} from "vue";
import desk from "@/main.vue";
import i18n from '@/locales/i18n';
import router from "@/routes/index";
import elementPlus from "element-plus";
import elementPlusLanguage from 'element-plus/es/locale/lang/en';

// helpers //
import filters from '@/core/helpers/filters';

// storage //
import store from "@/store/index";

// services //
//import mockService from "@/core/services/mockService";
import apiService from "@/core/services/apiService";
import layoutService from "@/core/services/layoutService";

// plugins //
import {initApexCharts} from "@/core/plugins/apexcharts";
import {initInlineSvg} from "@/core/plugins/inline-svg";
import {initVeeValidate} from "@/core/plugins/vee-validate";
import VueSimpleContextMenu from 'vue-simple-context-menu';
import "@/core/plugins/bootstrap";
import "@/core/plugins/themes";
import "@/core/plugins/prismjs";
import * as ElementPlusIconsVue from '@element-plus/icons-vue';

// https://www.npmjs.com/package/vuejs3-google-maps
import VueGeolocation from "vue3-geolocation";
import GMaps from "vuejs3-google-maps";

// vo-components //
import {createGlobalComponents} from "@/core/components/components";

// fire... //
const virtualoffice = createApp(desk, {});

virtualoffice.use(store);
virtualoffice.use(router);
virtualoffice.use(elementPlus, {locale: elementPlusLanguage, size: 'default', zIndex: 3000});
virtualoffice.use(i18n);

// @ToDo kann eigentlich wieder raus aber dann geht der geocoder autocompleter nicht
virtualoffice.use(VueGeolocation);
virtualoffice.use(GMaps, {
  load: {
    apiKey: process.env.VUE_APP_GOOGLE_API_KEY, // KLIXX API KEY
    libraries: ["places"],
  },
});

createGlobalComponents(virtualoffice);
initApexCharts(virtualoffice);
initInlineSvg(virtualoffice);
initVeeValidate();

apiService.init(virtualoffice);
layoutService.init();

// add global $filters //
virtualoffice.config.globalProperties.$filters = filters;

// add global props if needed //
//virtualoffice.provide('whatever', 'huhu);

// mock service for local testing //
//mockService.init(virtualoffice);

// add icons //
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    virtualoffice.component(key, component);
}

// https://github.com/johndatserakis/vue-simple-context-menu
virtualoffice.component('vue-simple-context-menu', VueSimpleContextMenu);

virtualoffice.mount("#virtualoffice");
