enum Actions {
    ADD_BODY_CLASSNAME = "addBodyClassName",
    REMOVE_BODY_CLASSNAME = "removeBodyClassName",
    ADD_BODY_ATTRIBUTE = "addBodyAttribute",
    REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
    ADD_CLASSNAME = "addClassName",
    REFRESH_AUTH = "refreshAuth",
    REFRESH_ALLOCATIONS = "refreshAllocations",
    CHECK_AUTH = "checkAuth",
    LOGIN = "login",
    LOGIN_TOKEN = "loginToken",
    LOGOUT = "logout",
    RESOLVE_EMPTY = "resolveEmpty",
    REGISTER = "register",
    REGISTER_DONE = "registerDone",
    UPDATE_PASSWORD = "updateUser",
    UPDATE_PASSWORD_DONE = "updateUserDone",
    FORGOT_PASSWORD = "forgotPassword",
    FORGOT_PASSWORD_DONE = "forgotPasswordDone",
    SET_BREADCRUMB_ACTION = "setBreadcrumbAction",
    FETCH_NEW_MESSAGES = "fetchNewMessages",
    CLEAR_NEW_MESSAGES = "clearNewMessages",
    FETCH_USER = "fetchUser",
    SET_USER_LAUNCHED_MODULE = 'setUserLaunchedModule',
    CLOSE_LAUNCHED_MODULE = 'closeLaunchedModule',
    ADD_ROUTE_HISTORY = 'addRouteHistory',
    IS_EDITING = 'actionIsEditing',
}

enum Mutations {
    SET_RENDER_UPDATE = "setMainRenderKey",
    SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
    PURGE_AUTH = "logOut",
    SET_AUTH = "setAuth",
    SET_TOKEN = "setToken",
    SET_ERROR = "setError",
    SET_EXCEPTION = "setException",
    SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
    SET_LAYOUT_CONFIG = "setLayoutConfig",
    RESET_LAYOUT_CONFIG = "resetLayoutConfig",
    OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
    OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",
    SET_NEW_MESSAGES = 'setNewMessages',
    SET_USER_NAVIGATION = 'setUserNavigation',
    SET_USER = 'setUser',
    SET_USER_MODULES = 'setUserModules',
    SET_USER_ALLOCATIONS = 'setUserAllocations',
    COMMIT_USER_LAUNCHED_MODULE = 'commitUserLaunchedModule',
    SET_ROUTE_HISTORY = 'setRouteHistory',
    RESET_HISTORY = 'resetHistory',
    SET_MODULE_LAST_ROUTE = 'setModuleLastRoute',
    SET_ACTIVE_MODULE = 'setActiveModule',
    SET_IS_EDITING = 'mutationIsEditing',
    STOP_POLLING = 'stopPolling',
}

const Configs = {
    POLLING_TIME_NEW_MESSAGES: 60000,
    NON_TOKEN_REFRESH_ROUTES: [
        'user/messages/'
    ]
}

export {Actions, Mutations, Configs};
