import {App} from "vue";
//import SidebarGeneral from "@/view/layout/sidebar/SidebarGeneral.vue";
//import SidebarShop from "@/view/layout/sidebar/SidebarShop.vue";//
//import SidebarUser from "@/view/layout/sidebar/SidebarUser.vue";

export function createGlobalComponents(app: App<Element>) {

    //Creates global components
    //app.component("vo-sidebar-general", SidebarGeneral);
    //app.component("vo-sidebar-shop", SidebarShop);
    //app.component("vo-sidebar-user", SidebarUser);


}
