const filters = {

    price(input: number) {
        if (isNaN(input)) {
            return "-";
        }
        return '$' + input.toFixed(2);
    },

    pretty(value) {
        return value;
    }

}
export default filters;