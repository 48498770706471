import {computed} from "vue";
import store from "@/store";
import router from "@/routes";
import ClipboardJS from "clipboard";
import papa from 'papaparse';

import Swal from "sweetalert2/dist/sweetalert2.min.js";
//import Swal from 'sweetalert2';

import {Actions} from "@/store/enums/store.enums";
import {ElementAnimateUtil} from "@/assets/ts/_utils/ElementAnimateUtil";

import itemsHelper from "@/modules/items/helpers/helpers";

/* eslint-disable  @typescript-eslint/no-explicit-any */

class utilsService {

    /**
     * Enables loader
     * @param {number} delay loading time in milliseconds
     * @returns {boolean}
     */
    public static loading = (delay: number): void => {
        // Add loading class to body
        store.dispatch(Actions.ADD_BODY_CLASSNAME, "page-loading");

        // Simulate the delay page loading
        setTimeout(() => {
            // Remove page loader after some time
            store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "page-loading");
        }, delay);
    };

    /**
     * Set header type
     * @returns {boolean}
     */
    public static headerType = computed(() => {
        return (
            (router.currentRoute.value.fullPath as string).indexOf("light") !== -1 ||
            (router.currentRoute.value.fullPath as string).indexOf("extended") !== -1
        );
    });

    /**
     * Set display header menu button
     * @returns {boolean}
     */
    public static headerTypeWithMenu = computed(() => {
        return (
            (router.currentRoute.value.fullPath as string).indexOf("compact") !== -1
        );
    });

    /**
     * Returns aside enabled meta propery
     * @returns {boolean}
     */
    public static asideEnabled = computed(() => {
        return router.currentRoute.value.meta.asideEnabled;
    });

    /**
     * Get asset absolute path
     * @param {string} pathname
     * @returns {boolean}
     */
    public static toAbsoluteUrl = (pathname: string) => {
        return process.env.BASE_URL + pathname;
    };

    /**
     * Sets current page breadcrumbs
     * @param {string} section Current page section name
     * @param {string} title Current page title name
     */
    public static setCurrentPageBreadcrumbs = (
        section: string,
        title: string
    ): void => {
        store.dispatch(Actions.SET_BREADCRUMB_ACTION, {
            section: section,
            title: title
        });
    };

    /**
     * Sets current page breadcrumbs
     * @param {string} title Current page title name
     */
    public static setCurrentPageTitle = (title: string): void => {
        store.dispatch(Actions.SET_BREADCRUMB_ACTION, {
            title: title
        });
    };

    /**
     * Return name of the theme
     * @returns {string}
     */
    public static themeName = computed(() => {
        return store.getters.layoutConfig("name");
    });

    /**
     * Return version of the theme
     * @returns {string}
     */
    public static version = computed(() => {
        return store.getters.layoutConfig("version");
    });

    /**
     * Return full name of the theme
     * @returns {string}
     */
    public static themeFullName = computed(() => {
        return store.getters.layoutConfig("fullName");
    });

//code copy button initialization
    public static useCopyClipboard() {

        const _init = element => {
            let elements = element;

            if (typeof elements === "undefined") {
                elements = document.querySelectorAll(".highlight");
            }

            if (elements && elements.length > 0) {
                for (let i = 0; i < elements.length; ++i) {
                    const highlight = elements[i];
                    const copy = highlight.querySelector(".highlight-copy");

                    if (copy) {
                        const clipboard = new ClipboardJS(copy, {
                            target: (trigger): any => {
                                const highlight = trigger.closest(".highlight");

                                if (highlight) {
                                    let el: Element | null = highlight.querySelector(
                                        ".tab-pane.active"
                                    );

                                    if (el == null) {
                                        el = highlight.querySelector(".highlight-code");
                                    }

                                    return el as Element;
                                }

                                return highlight;
                            }
                        });

                        clipboard.on("success", e => {
                            const caption = e.trigger.innerHTML;

                            e.trigger.innerHTML = "copied";
                            e.clearSelection();

                            setTimeout(function () {
                                e.trigger.innerHTML = caption;
                            }, 2000);
                        });
                    }
                }
            }
        };

        return {
            init: (element?) => {
                _init(element);
            }
        };
    };

    /**
     * Show auth form by name
     * @param {string} formName Name of the form which will be shown
     * @returns {boolean}
     */
    public static showForm = formName => {
        const _signinForm = document.getElementById("vo_login_signin_form");
        const _signupForm = document.getElementById("vo_login_signup_form");
        const _passwordResetForm = document.getElementById(
            "vo_login_password_reset_form"
        );

        let form;

        _signinForm?.classList.add("d-none");
        _signupForm?.classList.add("d-none");
        _passwordResetForm?.classList.add("d-none");

        if (formName === "signin") {
            form = _signinForm;
        }

        if (formName === "signup") {
            form = _signupForm;
        }

        if (formName === "password_reset") {
            form = _passwordResetForm;
        }

        form.classList.remove("d-none");
        form.style["animationDuration"] = "0.6s";
        ElementAnimateUtil.animateClass(form, "animate__animated animate__backInUp");
    };

    public static log(message) {
        console.log(message);
    }

    public static startEditMode() {
        store.dispatch(Actions.IS_EDITING, true);
    }

    public static endEditMode() {
        store.dispatch(Actions.IS_EDITING, false);
    }

    public static purgeAuth() {
        store.dispatch(Actions.LOGOUT, false);
    }

    public static showConfirmationOverlay(headline: string, body: string, labelConfirmButton: string, labelCancelButton: string, onConfirm: any) {

        const confirmHeadline = headline || '';
        const confirmBody = body || '';
        const confirmButtonText = labelConfirmButton || 'OK';
        const cancelButtonText = labelCancelButton || null;

        Swal.fire({
            title: confirmHeadline,
            html: confirmBody,
            icon: "warning",
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            preConfirm: onConfirm,
            showConfirmButton: true,
            showCancelButton: !!cancelButtonText,
            buttonsStyling: false,
            confirmButtonText: confirmButtonText,
            cancelButtonText: cancelButtonText,
            customClass: {
                confirmButton: "btn fw-bold btn-success text-white",
                cancelButton: "btn fw-bold btn-danger text-white"
            }
        });

    }

    public static showSuccessOverlay(headline?: string, body?: string, labelConfirmButton?: string) {

        const confirmHeadline = headline || '';
        const confirmBody = body || '';
        const confirmButtonText = labelConfirmButton || 'OK';

        Swal.fire({
            title: confirmHeadline,
            html: confirmBody,
            icon: "success",
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: true,
            showCancelButton: false,
            buttonsStyling: false,
            confirmButtonText,
            customClass: {
                confirmButton: "btn fw-bold btn-success text-white"
            }
        });

    }

    public static showSolutionOverlay(constraintsOptions, constraintsUsages, onConfirm, headline?: string, selectLabel?: string, labelConfirmButton?: string, labelCancelButton?: string) {

        const confirmHeadline = headline || '';
        const confirmSelectLabel = selectLabel || '';
        const confirmButtonText = labelConfirmButton || 'OK';
        const cancelButtonText = labelCancelButton || 'Cancel';

        Swal.fire({
            title: confirmHeadline,
            html: constraintsUsages,
            icon: "warning",
            input: 'select',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            preConfirm: onConfirm,
            inputLabel: confirmSelectLabel,
            inputOptions: constraintsOptions,
            showConfirmButton: true,
            showCancelButton: true,
            buttonsStyling: false,
            confirmButtonText: confirmButtonText,
            cancelButtonText: cancelButtonText,
            customClass: {
                confirmButton: "btn fw-bold btn-success text-white",
                cancelButton: "btn fw-bold btn-danger text-white"
            }
        });

    }

    public static showErrorOverlay(text?: string, labelCloseButton?: string) {

        const confirmButtonText = labelCloseButton || 'OK';

        Swal.fire({
            text: text,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: confirmButtonText,
            customClass: {
                confirmButton: "btn fw-bold btn-danger text-white"
            }
        });

    }

    public static showStaticOverlay(templateId: string, onConfirm?) {

        Swal.fire({
            template: '#' + templateId,
            preConfirm: onConfirm,
        });

    }

    public static showSuccessToast(text?: string) {

        Swal.fire({
            position: 'bottom-start',
            padding: '0.5rem',
            toast: true,
            icon: "success",
            title: text,
            showConfirmButton: false,
            timer: 1500,
            timerProgressBar: true
        });

    }

    public static showErrorToast(text?: string, labelCloseButton?: string) {

        const confirmButtonText = labelCloseButton || 'OK';

        Swal.fire({
            position: 'bottom-start',
            padding: '0.5rem',
            toast: true,
            icon: "error",
            title: text,
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            confirmButtonText: confirmButtonText,
            customClass: {
                confirmButton: "btn fw-bold btn-light-danger"
            }
        });

    }

    public static setLocalStorage(key, data) {

        let prefix = this.getLocalStoragePrefix();
        localStorage.setItem(prefix + key, data);

    }

    public static getLocalStorage(key) {

        let prefix = this.getLocalStoragePrefix();
        if (localStorage.getItem(prefix + key)) {
            return localStorage.getItem(prefix + key);
        }

        return null;

    }

    public static clearLocalStorage(key) {

        let prefix = this.getLocalStoragePrefix();
        if (typeof key !== 'undefined') {
            localStorage.removeItem(prefix + key);
            return;
        }

        localStorage.clear();
    }

    public static getLocalStoragePrefix() {

        return 'vo-storage-';

    }

    public static purgeObject(object) {

        let cleanupObject = {};
        for (const key in object) {
            cleanupObject[key] = '';
        }

        return cleanupObject;

    }

    public static checkIfEmpty(element) {

        if (!element) {
            return true;
        }

        if (Object.getPrototypeOf(element) === Object.prototype && Object.keys(element).length === 0) {
            return true;
        }

        if (element.length === 0) {
            return true;
        }

        return false;

    }

    public static transformBool(valueToCheck) {

        return (valueToCheck === true || parseInt(valueToCheck) === 1);

    }

    public static transformTimestamp(timestamp, inclTime = false, onlyTime = false) {

        if (isNaN(timestamp) || !parseInt(timestamp)) {
            return '-';
        }

        const a = new Date(timestamp * 1000);

        let year = a.getFullYear();
        let month = a.getUTCMonth() + 1;
        let day = a.getDate();
        let hour = a.getHours();
        let min = a.getMinutes();
        let sec = a.getSeconds();

        if (onlyTime) {
            return hour + ':' + min;
        }

        if (inclTime) {
            return year + '-' + month + '-' + day + ' ' + hour + ':' + min;
        }

        return year + '-' + month + '-' + day;

    }

    public static cleanInputString(inputString, checkType, maxInput = 999) {

        let _inputString = inputString && inputString.toString() || '';
        let _maxInput = maxInput || 0;
        let _checkType = checkType || 'string';

        if (_inputString.length > _maxInput) {
            _inputString = _inputString.substring(0, _maxInput);
        }

        switch (_checkType) {

            case 'int' :
                _inputString = _inputString.replace(/[^0-9]/g, '');
                break;

            case 'float' :

                _inputString = _inputString.replace(/[,]/gi, ".").replace(/[e+\-]/gi, "").replace(/[^0-9.]/g, '');
                if ((_inputString.match(/[.]/gi) || []).length > 1) {
                    _inputString = _inputString.slice(0, -1);
                }

                break;

            case 'lettersOnly' :
                _inputString = _inputString.replace(/[^´`~'.&äüöÄÜÖñéÉèÈáÁàÀâæêëîïôòœûùçßa-zA-Z /.-]/g, '');
                break;

            case 'string' :
            case 'stringCapitalize' :
            default:
                _inputString = _inputString.replace(/[^´`~'.&äüöÄÜÖñéÉèÈáÁàÀâæêëîïôòœûùçßa-zA-Z0-9 /.-]/g, '');
                break;

        }

        if (_checkType === 'stringCapitalize') {
            _inputString = _inputString.toUpperCase();
        }

        return _inputString;

    }

    public static calcInchToCm(value) {

        value = this.cleanInputString(value, 'float');
        return ((value || 0) * 2.54).toFixed(0);

    }

    public static calcPoundToKg(value) {

        value = this.cleanInputString(value, 'float');
        return ((value || 0) / 2.205).toFixed(2);

    }

    public static calcCmToInch(value) {

        value = this.cleanInputString(value, 'float');
        return ((value || 0) * 0.3937).toFixed(2);

    }

    public static calcKgToPound(value) {

        value = this.cleanInputString(value, 'float');
        return ((value || 0) * 2.205).toFixed(2);

    }

    public static calcInchToSqft(value) {

        value = this.cleanInputString(value, 'float');
        return value * 0.006944458335;

    }

    public static calcInchToCuft(value) {

        value = this.cleanInputString(value, 'float');
        return value / 1728;

    }

    public static calcCmToQm(value) {

        value = this.cleanInputString(value, 'float');
        return value / 10000;

    }

    public static calcCmToQm3(value) {

        value = this.cleanInputString(value, 'float');
        return value / 1000000;

    }

    public static getTotalItemCount(secondaryUnitsPerPrimaryUnit, productsPerSecondaryUnit, receivingAmount) {

        let totalCount = 0;
        if (parseInt(secondaryUnitsPerPrimaryUnit) && parseInt(productsPerSecondaryUnit) && parseInt(receivingAmount)) {
            totalCount = parseInt(secondaryUnitsPerPrimaryUnit) * parseInt(productsPerSecondaryUnit) * parseInt(receivingAmount)
        }

        return totalCount;

    }

    public static confirmUserRights(module, action) {

        if (!module || !action) {
            return false;
        }

        const userModules = store.getters.getUserModules;
        for (let checkModule of userModules) {

            const checkIdentifier = checkModule.name || null;
            if (checkIdentifier && checkIdentifier === module) {

                const checkRights = checkModule.rights || {};
                if (checkRights.hasOwnProperty(action)) {
                    return checkRights[action];
                }

            }

        }

        return false;

    }

    public static openWindow(options) {

        let {target, params, w, h} = options;

        let routerData = router.resolve({
            name: target,
            params
        });

        if (!routerData.href) {
            alert('No Target to Print');
            return;
        }

        if (!w) {
            w = 800;
        }

        if (!h) {
            h = 600;
        }

        const top = window.top.outerHeight / 2 + window.top.screenY - (h / 2);
        const left = window.top.outerWidth / 2 + window.top.screenX - (w / 2);

        const newWindow = window.open(routerData.href, '', `scrollbars=yes,width=${w}, height=${h}, top=${top}, left=${left}`);
        if (window.focus) newWindow.focus();

    }

    public static parseImportString(string) {

        let result = papa.parse(string, {
            dynamicTyping: true,
            skipEmptyLines: true,
            //preview:10,
        });

        let cleanedResults = [];
        for (let line of result.data) {

            // remove lines where all cells are NULL //
            let addLine = false;
            for (let cell of line) {
                if (cell !== null) {
                    addLine = true;
                    break;
                }
            }

            if (addLine) {
                cleanedResults.push(line);
            }

        }

        return {
            data: cleanedResults,
            errors: result.errors,
            meta: result.meta
        };

    }

    public static getDrawerWith() {

        const windowWidth = window.innerWidth;

        if (windowWidth < 850) return '100%';
        if (windowWidth >= 850 && windowWidth < 1200) return '75%';
        if (windowWidth >= 1200 && windowWidth < 1600) return '60%';
        if (windowWidth >= 1600 && windowWidth < 1800) return '60%';
        if (windowWidth >= 1800) return '50%';

    }

        public static getDialogWith() {

        const windowWidth = window.innerWidth;

        if (windowWidth < 650) return '95%';
        if (windowWidth >= 650 && windowWidth < 1200) return '50%';
        if (windowWidth >= 1200 && windowWidth < 1400) return '40%';
        if (windowWidth >= 1400) return '30%';

    }

    public static getRandomValue() {
        return new Date().getTime();
    }

    public static getPasswordRegex() {
        return /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[/*'#,.\-;:_!"§$%&()=?+]).{8,}$/;
    }

    public static getCurrencies() {

        return [
            {
                label: 'US-Dollar',
                value: 'USD',
            },
            {
                label: 'Euro',
                value: 'EUR',
            },
            {
                label: 'Pfund',
                value: 'GBP',
            },
        ]

    }

    public static countryWithNumberBeforeStreet(country:string) {

        return ["IT", "FR", "US", "GB"].includes(country);

    }

}

export default utilsService;
