export type State = { classes: object };
import {Module, VuexModule, Mutation, Action} from "vuex-module-decorators";
import {Actions, Mutations} from "@/store/enums/store.enums";

export interface StoreInfo {
    classes: object;
    renderKey:Number;
}

@Module
export default class BodyModule extends VuexModule implements StoreInfo {
    classes = {};
    renderKey = 1;

    /**
     * Get current page title
     * @returns string
     */
    get getClasses(): object {
        return this.classes;
    }

    get getMainRenderKey() {
        return 'virtualoffice-' + this.renderKey;
    }

    /**
     * MagicKey to force rerender everything
     */
    @Mutation
    [Mutations.SET_RENDER_UPDATE]() {
        this.renderKey++;
    }

    @Mutation
    [Mutations.SET_CLASSNAME_BY_POSITION](payload) {
        const {position, className} = payload;
        if (!this.classes[position]) {
            this.classes[position] = [];
        }
        this.classes[position].push(className);
    }

    @Action
    [Actions.ADD_BODY_CLASSNAME](className) {
        document.body.classList.add(className);
    }

    @Action
    [Actions.REMOVE_BODY_CLASSNAME](className) {
        document.body.classList.remove(className);
    }

    @Action
    [Actions.ADD_BODY_ATTRIBUTE](payload) {
        const {qualifiedName, value} = payload;
        document.body.setAttribute(qualifiedName, value);
    }

    @Action
    [Actions.REMOVE_BODY_ATTRIBUTE](payload) {
        const {qualifiedName} = payload;
        document.body.removeAttribute(qualifiedName);
    }

    @Action
    [Actions.ADD_CLASSNAME](payload) {
        this.context.commit(Mutations.SET_CLASSNAME_BY_POSITION, payload);
    }
}
