enum LicensesActions {
    FETCH_LIST = "licensesFetchList",
    GET_ITEM = "licensesGetItem",
    CREATE_ITEM = "licensesCreateItem",
    UPDATE_ITEM = "licensesUpdateItem",
    DELETE_ITEM = "licensesDeleteItem",
    REFRESH_LIST = "licensesRefreshList"
}

enum LicensesMutation {
    SET_RENDER_UPDATE = "licensesListRenderNeeded",
    SET_LIST_RESULT = "licensesListResult"
}

export {LicensesActions, LicensesMutation};