enum InventoryActions {
    FETCH_LIST = "inventoryFetchList",
    GET_ITEM = "inventoryGetItem",
    CREATE_ITEM = "inventoryCreateItem",
    UPDATE_ITEM = "inventoryUpdateItem",
    DELETE_ITEM = "inventoryDeleteItem",
    REFRESH_LIST = "inventoryRefreshList",
    MOVE_ITEM = "inventoryMoveItem",
    SEARCH_INVENTORY = "inventorySearch",
}

enum InventoryMutation {
    SET_RENDER_UPDATE = "inventoryListRenderNeeded",
    SET_LIST_RESULT = "inventoryListResult"
}

export {InventoryActions, InventoryMutation};