enum ItemsActions {
    FETCH_LIST = "itemsFetchList",
    GET_ITEM = "itemsGetItem",
    CREATE_ITEM = "itemsCreateItem",
    UPDATE_ITEM = "itemsUpdateItem",
    DELETE_ITEM = "itemsDeleteItem",
    REFRESH_LIST = "itemsRefreshList",
    SEARCH_ITEMS = "itemsSearch",
    IMPORT_ITEMS = "itemsImport",
    GET_VARIANTS = "itemsGetVariants",
}

enum ItemsMutation {
    SET_RENDER_UPDATE = "itemsListRenderNeeded",
    SET_LIST_RESULT = "itemsListResult"
}

export {ItemsActions, ItemsMutation};