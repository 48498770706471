enum ReceivingActions {
    FETCH_LIST = "receivingFetchList",
    GET_ITEM = "receivingGetItem",
    CREATE_ITEM = "receivingCreateItem",
    UPDATE_ITEM = "receivingUpdateItem",
    DELETE_ITEM = "receivingDeleteItem",
    REFRESH_LIST = "receivingRefreshList",
    CONFIRM_RECEIPT = "receivingConfirmReceipt",
    UPLOAD_DATA = "receivingUploadData",
}

enum ReceivingMutation {
    SET_RENDER_UPDATE = "receivingListRenderNeeded",
    SET_LIST_RESULT = "receivingListResult"
}

export {ReceivingActions, ReceivingMutation};