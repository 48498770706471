import {computed} from "vue";
import store from "@/store";
import router from "@/routes";

/**
 * Returns layout config
 * @returns {object}
 */
export const config = computed(() => {
    return store.getters.layoutConfig();
});

/**
 * Set the sidebar display
 * @returns {boolean}
 */
export const displaySidebar = computed(() => {
    return store.getters.layoutConfig("sidebar.display");
});

/**
 * Get the apiHost
 * @returns {boolean}
 */
export const apiHost = computed(() => {
    return store.getters.layoutConfig("apiHost");
});

/**
 * Get the apiVersion
 * @returns {boolean}
 */
export const apiVersion = computed(() => {
    return store.getters.layoutConfig("apiVersion");
});

/**
 * Get the apiVersion
 * @returns {boolean}
 */
export const apiClient = computed(() => {
    return store.getters.layoutConfig("apiClient");
});

/**
 * Set the aside display
 * @returns {boolean}
 */
export const displayMainMenu = computed(() => {
    return store.getters.layoutConfig("aside.display");
});

/**
 * Set mobile header position
 * @returns {boolean}
 */
export const fixedMobileHeader = computed(() => {
    return store.getters.layoutConfig("header.fixed.tabletAndMobile");
});

/**
 * Set the loader display
 * @returns {boolean}
 */
export const loaderEnabled = computed(() => {
    return store.getters.layoutConfig("loader.display");
});

/**
 * Check if content width is fluid
 * @returns {boolean}
 */
export const contentFluid = computed(() => {
    return store.getters.layoutConfig("content.width") === "fluid";
});

/**
 * Set the scroll top display
 * @returns {boolean}
 */
export const displayScrollTop = computed(() => {
    return store.getters.layoutConfig("scrollTop.display");
});

/**
 * Set the scroll top display
 * @returns {boolean}
 */
export const displayHeader = computed(() => {
    return store.getters.layoutConfig("header.display");
});

/**
 * Returns showSearch options
 * @returns {boolean}
 */
export const showSearch = computed(() => {
    return store.getters.layoutConfig("header.showSearch");
});

/**
 * Set the toolbar display
 * @returns {boolean}
 */
export const displayToolbar = computed(() => {
    return store.getters.layoutConfig("toolbar.display");
});

/**
 * Return type of the loader
 * @returns {boolean}
 */
export const loaderType = computed(() => {
    return store.getters.layoutConfig("loader.type");
});

/**
 * Check if footer width is fluid
 * @returns {boolean}
 */
export const footerWidthFluid = computed(() => {
    return store.getters.layoutConfig("footer.width") === "fluid";
});

/**
 * Check if toolbar width is fluid
 * @returns {boolean}
 */
export const toolbarWidthFluid = computed(() => {
    return store.getters.layoutConfig("toolbar.width") === "fluid";
});

/**
 * Check if header width is fluid
 * @returns {boolean}
 */
export const headerWidthFluid = computed(() => {
    return store.getters.layoutConfig("header.width") === "fluid";
});

/**
 * Set the aside primary display
 * @returns {boolean}
 */
export const displayAsidePrimary = computed(() => {
    return store.getters.layoutConfig("aside.primaryDisplay");
});

/**
 * Set the aside secondary display
 * @returns {boolean}
 */
export const displayAsideSecondary = computed(() => {
    return store.getters.layoutConfig("aside.secondaryDisplay");
});

/**
 * Set the aside secondary display
 * @returns {boolean}
 */
export const displayAsideSecondaryAsHover = computed(() => {
    return store.getters.layoutConfig("aside.secondaryDisplayAsHover");
});

/**
 * Set the toggle button display
 * @returns {boolean}
 */
export const displayToggleButton = computed(() => {
    return store.getters.layoutConfig("aside.toggle");
});

/**
 * Returns aside minimized options
 * @returns {boolean}
 */
export const minimizedByDefault = computed(() => {
    return store.getters.layoutConfig("aside.defaultMinimized");
});

export const tabPosition = computed(() => {
    return 'right'; // left, top, right
});

export const tabType = computed(() => {
    return ''; // card, border-card, ''
});
