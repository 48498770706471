enum DashboardActions {
    GET_STATS = "dashboardGetStats"
}

enum DashboardMutation {
    SET_STATS = "dashboardSetStats",
    SET_RENDER_UPDATE = "dashboardSetRenderUpdate",

}

export {DashboardActions, DashboardMutation};
