import apiService from "@/core/services/apiService";

export type State = { classes: object };
import {Module, VuexModule, Mutation, Action} from "vuex-module-decorators";
import {Mutations} from "@/store/enums/store.enums";
import {ConnectivityActions, ConnectivityMutation} from "@/modules/connectivity/store/enums";

@Module
export default class Store extends VuexModule {
    renderKey = 1;
    listParams = {
        page: 1,
        results: 10,
        search: null,
        sort: [],
        filter: {}
    };
    listCache = [];
    listTotal = 0;

    get getConnectivityRenderKey() {
        return 'connectivity-' + this.renderKey;
    }

    get getConnectivityLastListResult() {
        return this.listCache;
    }

    get getConnectivitysLastListTotal() {
        return this.listTotal;
    }

    @Mutation
    [ConnectivityMutation.SET_RENDER_UPDATE]() {
        this.renderKey++;
    }

    @Mutation
    [ConnectivityMutation.SET_LIST_RESULT]({listParams, listTotal, listCache}) {
        this.listParams = listParams;
        this.listCache = listCache;
        this.listTotal = listTotal;
    }

    @Action
    [ConnectivityActions.REFRESH_LIST]() {
        this.context.dispatch(ConnectivityActions.FETCH_LIST, this.listParams);
    }

    @Action
    [ConnectivityActions.FETCH_LIST](params) {

        return new Promise<void>((resolve, reject) => {

            apiService.post("connectivity/list", params).then((response) => {

                const data = response.data || null;

                const _verified = apiService.verifyApiResult(data);
                if (!_verified) {
                    this.context.commit(Mutations.SET_ERROR, data);
                    return reject();
                }

                // return results into storage for reactive update if needed //
                this.context.commit(ConnectivityMutation.SET_LIST_RESULT, {
                    listParams: params,
                    listTotal: data.results,
                    listCache: data.result
                });
                resolve();

            }).catch((response) => {

                this.context.commit(Mutations.SET_ERROR, response);
                reject();

            });

        });

    };

    @Action
    [ConnectivityActions.GET_ITEM](detailId) {

        return new Promise<void>((resolve, reject) => {

            apiService.get(`connectivity/get/${detailId}`).then(({data}) => {

                const _verified = apiService.verifyApiResult(data);
                if (!_verified) {
                    this.context.commit(Mutations.SET_ERROR, data);
                    return reject();
                }

                // return results to dispatcher without mutation //
                resolve(data.result);

            }).catch((response) => {

                this.context.commit(Mutations.SET_ERROR, response);
                reject();

            });

        });

    };

    @Action
    [ConnectivityActions.CREATE_ITEM](payload) {

        return new Promise((resolve, reject) => {

            const {data} = payload;

            apiService.post('connectivity/create/', data).then(({data}) => {

                const _verified = apiService.verifyApiResult(data);
                if (!_verified) {
                    this.context.commit(Mutations.SET_ERROR, data);
                    return reject();
                }

                // flag cached keep alive comp to render new //
                this.context.commit(ConnectivityMutation.SET_RENDER_UPDATE);

                // refresh list in background//
                this.context.dispatch(ConnectivityActions.REFRESH_LIST);

                // return results to dispatcher without mutation //
                resolve(data.result);

            }).catch((response) => {

                this.context.commit(Mutations.SET_ERROR, response);
                reject();

            });

        });

    };

    @Action
    [ConnectivityActions.UPDATE_ITEM](payload) {

        return new Promise((resolve, reject) => {

            const {editId, data} = payload;

            apiService.put(`connectivity/edit/${editId}`, data).then(({data}) => {

                const _verified = apiService.verifyApiResult(data);
                if (!_verified) {
                    this.context.commit(Mutations.SET_ERROR, data);
                    return reject();
                }

                // refresh list in background//
                this.context.dispatch(ConnectivityActions.REFRESH_LIST);

                // return results to dispatcher without mutation //
                resolve(data.result);

            }).catch((response) => {

                this.context.commit(Mutations.SET_ERROR, response);
                reject();

            });

        });

    };

    @Action
    [ConnectivityActions.DELETE_ITEM](editId) {

        return new Promise((resolve, reject) => {

            apiService.delete(`connectivity/delete/${editId}`).then(({data}) => {

                const _verified = apiService.verifyApiResult(data);
                if (!_verified) {
                    this.context.commit(Mutations.SET_ERROR, data);
                    return reject();
                }

                // refresh list in background//
                this.context.dispatch(ConnectivityActions.REFRESH_LIST);

                // return results to dispatcher without mutation //
                resolve(data.result);

            }).catch((response) => {

                this.context.commit(Mutations.SET_ERROR, response);
                reject();

            });

        });

    };

}
