import apiService from "@/core/services/apiService";

export type State = { classes: object };
import {Module, VuexModule, Mutation, Action} from "vuex-module-decorators";
import {Mutations} from "@/store/enums/store.enums";
import {InventoryActions, InventoryMutation} from "@/modules/inventory/store/enums";
import {AxiosResponse} from "axios";

@Module
export default class Store extends VuexModule {
    renderKey = 1;

    listParams = {
        page: 1,
        results: 10,
        search: null,
        sort: [],
        filter: {}
    };

    listCache = [];
    listTotal = 0;
    listFilterOptions = {};

    formQuarantineReasons = [
        {
            label: 'Damaged',
            value: 'damaged',
        },
        {
            label: 'QC',
            value: 'qc',
        },
        {
            label: 'Customer Request',
            value: 'customer-request',
        },
        {
            label: 'Materials',
            value: 'materials',
        },
        {
            label: 'Split',
            value: 'split',
        },
        {
            label: 'Other',
            value: 'other',
        },
    ];

    get getInventoryRenderKey() {
        return 'inventory-' + this.renderKey;
    }

    get getInventoryLastListResult() {
        return this.listCache;
    }

    get getInventoryLastFilterOptions() {
        return this.listFilterOptions;
    }

    get getInventoryLastListTotal() {
        return this.listTotal;
    }

    get getQuarantineReasons() {
        return this.formQuarantineReasons;
    }

    @Mutation
    [InventoryMutation.SET_RENDER_UPDATE]() {
        this.renderKey++;
    }

    @Mutation
    [InventoryMutation.SET_LIST_RESULT]({listParams, listTotal, listCache, listFilterOptions}) {
        this.listParams = listParams;
        this.listCache = listCache;
        this.listFilterOptions = listFilterOptions;
        this.listTotal = listTotal;
    }

    @Action
    [InventoryActions.REFRESH_LIST]() {
        this.context.dispatch(InventoryActions.FETCH_LIST, this.listParams);
    }

    @Action
    [InventoryActions.FETCH_LIST](params) {

        return new Promise<void>((resolve, reject) => {

            apiService.post("inventory/list", params).then((response) => {

                const data = response.data || null;

                const _verified = apiService.verifyApiResult(data);
                if (!_verified) {
                    this.context.commit(Mutations.SET_ERROR, data);
                    return reject();
                }

                // return results into storage for reactive update if needed //
                this.context.commit(InventoryMutation.SET_LIST_RESULT, {
                    listParams: params,
                    listTotal: data.results,
                    listCache: data.result,
                    listFilterOptions: data.filter
                });
                resolve();

            }).catch((response) => {

                this.context.commit(Mutations.SET_ERROR, response);
                reject();

            });

        });

    };

    @Action
    [InventoryActions.GET_ITEM](detailId) {

        return new Promise<void>((resolve, reject) => {

            apiService.get(`inventory/get/${detailId}`).then(({data}) => {

                const _verified = apiService.verifyApiResult(data);
                if (!_verified) {
                    this.context.commit(Mutations.SET_ERROR, data);
                    return reject();
                }

                // return results to dispatcher without mutation //
                resolve(data.result);

            }).catch((response) => {

                this.context.commit(Mutations.SET_ERROR, response);
                reject();

            });

        });

    };

    @Action
    [InventoryActions.CREATE_ITEM](payload) {

        return new Promise((resolve, reject) => {

            const {data} = payload;

            apiService.post('inventory/create/', data).then(({data}) => {

                const _verified = apiService.verifyApiResult(data);
                if (!_verified) {
                    this.context.commit(Mutations.SET_ERROR, data);
                    return reject();
                }

                // flag cached keep alive comp to render new //
                this.context.commit(InventoryMutation.SET_RENDER_UPDATE);

                // refresh list in background//
                this.context.dispatch(InventoryActions.REFRESH_LIST);

                // return results to dispatcher without mutation //
                resolve(data.result);

            }).catch((response) => {

                this.context.commit(Mutations.SET_ERROR, response);
                reject();

            });

        });

    };

    @Action
    [InventoryActions.UPDATE_ITEM](payload) {

        return new Promise((resolve, reject) => {

            const {editId, data} = payload;

            apiService.put(`inventory/edit/${editId}`, data).then(({data}) => {

                const _verified = apiService.verifyApiResult(data);
                if (!_verified) {
                    this.context.commit(Mutations.SET_ERROR, data);
                    return reject();
                }

                // refresh list in background//
                this.context.dispatch(InventoryActions.REFRESH_LIST);

                // flag cached keep alive comp to render new //
                // @ToDo Notwendig??
                //this.context.commit(InventoryMutation.SET_RENDER_UPDATE);

                // return results to dispatcher without mutation //
                resolve(data.result);

            }).catch((response) => {

                this.context.commit(Mutations.SET_ERROR, response);
                reject();

            });

        });

    };

    @Action
    [InventoryActions.MOVE_ITEM](payload) {

        return new Promise<void>((resolve, reject) => {

            const {editId, data} = payload;

            apiService.put(`inventory/move/${editId}`, data).then(({data}) => {

                const _verified = apiService.verifyApiResult(data);
                if (!_verified) {
                    this.context.commit(Mutations.SET_ERROR, data);
                    return reject();
                }

                // refresh list in background//
                this.context.dispatch(InventoryActions.REFRESH_LIST);

                // return results to dispatcher without mutation //
                resolve();

            }).catch((response) => {

                this.context.commit(Mutations.SET_ERROR, response);
                reject();

            });

        });

    };

    @Action
    [InventoryActions.DELETE_ITEM](editId) {

        return new Promise((resolve, reject) => {

            apiService.delete(`inventory/delete/${editId}`).then(({data}) => {

                const _verified = apiService.verifyApiResult(data);
                if (!_verified) {
                    this.context.commit(Mutations.SET_ERROR, data);
                    return reject();
                }

                // refresh list in background//
                this.context.dispatch(InventoryActions.REFRESH_LIST);

                // return results to dispatcher without mutation //
                resolve(data.result);

            }).catch((response) => {

                this.context.commit(Mutations.SET_ERROR, response);
                reject();

            });

        });

    };

    @Action
    [InventoryActions.SEARCH_INVENTORY](params) {

        return new Promise((resolve, reject) => {

            apiService.post(`inventory/search`, params).then((response: AxiosResponse) => {

                const data = response.data || null;

                const _verified = apiService.verifyApiResult(data);
                if (!_verified) {
                    this.context.commit(Mutations.SET_ERROR, data);
                    return reject();
                }

                resolve(data);

            }).catch((response) => {

                this.context.commit(Mutations.SET_ERROR, response);
                reject();

            });

        });

    };

}
