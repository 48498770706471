enum ConnectivityActions {
    FETCH_LIST = "connectivityFetchList",
    GET_ITEM = "connectivityGetItem",
    CREATE_ITEM = "connectivityCreateItem",
    UPDATE_ITEM = "connectivityUpdateItem",
    DELETE_ITEM = "connectivityDeleteItem",
    REFRESH_LIST = "connectivityRefreshList"
}

enum ConnectivityMutation {
    SET_RENDER_UPDATE = "connectivityListRenderNeeded",
    SET_LIST_RESULT = "connectivityListResult"
}

export {ConnectivityActions, ConnectivityMutation};