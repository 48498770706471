import apiService from "@/core/services/apiService";

export type State = { classes: object };
import {Module, VuexModule, Mutation, Action} from "vuex-module-decorators";
import {Actions, Mutations} from "@/store/enums/store.enums";
import {CustomersActions, CustomersMutation} from "@/modules/customers/store/enums";
import {UsersActions} from "@/modules/users/store/enums";
import {localesOptions, countryOptions} from "@/locales/locales";

@Module
export default class Store extends VuexModule {
    renderKey = 1;
    currentCustomer: {};
    listParams = {
        page: 1,
        results: 10,
        search: null,
        sort: [],
        filter: {}
    };
    listCache = [];
    listTotal = 0;

    get getCustomersRenderKey() {
        return 'customers-' + this.renderKey;
    }

    get getCustomersLastListResult() {
        return this.listCache;
    }

    get getCustomersMappingFields() {

        return {
            contactPerson: {
                required: true,
                label: 'common.name',
                mapping: null,
                unique: false,
                duplicates: null,
                options: null,
                optionsMapped: {}
            },
            contactPhone: {
                required: true,
                label: 'common.phone',
                mapping: null,
                unique: false,
                duplicates: null,
                options: null,
                optionsMapped: {}
            },
            contactEmail: {
                required: true,
                label: 'common.email',
                mapping: null,
                unique: true,
                duplicates: 'skip',
                options: null,
                optionsMapped: {}
            },
            companyName: {
                required: true,
                label: 'common.company',
                mapping: null,
                unique: false,
                duplicates: null,
                options: null,
                optionsMapped: {}
            },
            companyAddressLine1: {
                required: false,
                label: 'common.addressLine1',
                mapping: null,
                unique: false,
                duplicates: null,
                options: null,
                optionsMapped: {}
            },
            companyAddressLine2: {
                required: false,
                label: 'common.addressLine2',
                mapping: null,
                unique: false,
                duplicates: null,
                options: null,
                optionsMapped: {}
            },
            companyZip: {
                required: false,
                label: 'common.postcode',
                mapping: null,
                unique: false,
                duplicates: null,
                options: null,
                optionsMapped: {}
            },
            companyCity: {
                required: false,
                label: 'common.city',
                mapping: null,
                unique: false,
                duplicates: null,
                options: null,
                optionsMapped: {}
            },
            companyState: {
                required: false,
                label: 'common.region',
                mapping: null,
                unique: false,
                duplicates: null,
                options: null,
                optionsMapped: {}
            },
            companyCountryCode: {
                required: false,
                label: 'common.country',
                mapping: null,
                unique: false,
                duplicates: null,
                options: countryOptions,
                optionsMapped: {}
            }
        };

    }

    get getCustomersLastListTotal() {
        return this.listTotal;
    }

    get getCurrentCustomer() {
        return this.currentCustomer;
    }

    @Mutation
    [CustomersMutation.SET_RENDER_UPDATE]() {
        this.renderKey++;
    }

    @Mutation
    [CustomersMutation.SET_LIST_RESULT]({listParams, listTotal, listCache}) {
        this.listParams = listParams;
        this.listCache = listCache;
        this.listTotal = listTotal;
    }

    @Mutation
    [CustomersMutation.SET_CURRENT_CUSTOMER](customerData) {
        this.currentCustomer = {...customerData};
    }

    @Action
    [CustomersActions.REFRESH_LIST]() {
        this.context.dispatch(CustomersActions.FETCH_LIST, this.listParams);
    }

    @Action
    [CustomersActions.FETCH_LIST](params) {

        return new Promise<void>((resolve, reject) => {

            apiService.post("customers/list", params).then((response) => {

                const data = response.data || null;

                const _verified = apiService.verifyApiResult(data);
                if (!_verified) {
                    this.context.commit(Mutations.SET_ERROR, data);
                    return reject();
                }

                // return results into storage for reactive update if needed //
                this.context.commit(CustomersMutation.SET_LIST_RESULT, {
                    listParams: params,
                    listTotal: data.results,
                    listCache: data.result
                });

                resolve();

            }).catch((response) => {

                this.context.commit(Mutations.SET_ERROR, response);
                reject();

            });

        });

    };

    @Action
    [CustomersActions.GET_ITEM](detailId) {

        return new Promise<void>((resolve, reject) => {

            apiService.get(`customers/get/${detailId}`).then(({data}) => {

                const _verified = apiService.verifyApiResult(data);
                if (!_verified) {
                    this.context.commit(Mutations.SET_ERROR, data);
                    return reject();
                }

                this.context.commit(CustomersMutation.SET_CURRENT_CUSTOMER, data.result);
                resolve();

            }).catch((response) => {

                this.context.commit(Mutations.SET_ERROR, response);
                reject();

            });

        });

    };

    @Action
    [CustomersActions.CREATE_ITEM](payload) {

        return new Promise((resolve, reject) => {

            const {data} = payload;

            apiService.post('customers/create/', data).then(({data}) => {

                const _verified = apiService.verifyApiResult(data);
                if (!_verified) {
                    this.context.commit(Mutations.SET_ERROR, data);
                    return reject();
                }

                // flag cached keep alive comp to render new //
                this.context.commit(CustomersMutation.SET_RENDER_UPDATE);

                // refresh list in background//
                this.context.dispatch(CustomersActions.REFRESH_LIST);

                // refresh user customer allocations //
                this.context.dispatch(Actions.REFRESH_ALLOCATIONS);

                // return results to dispatcher without mutation //
                resolve(data.result);

            }).catch((response) => {

                this.context.commit(Mutations.SET_ERROR, response);
                reject();

            });

        });

    };

    @Action
    [CustomersActions.UPDATE_ITEM](payload) {

        return new Promise<void>((resolve, reject) => {

            const {editId, data} = payload;

            apiService.put(`customers/edit/${editId}`, data).then(({data}) => {

                const _verified = apiService.verifyApiResult(data);
                if (!_verified) {
                    this.context.commit(Mutations.SET_ERROR, data);
                    return reject();
                }

                // refresh list in background//
                this.context.dispatch(CustomersActions.REFRESH_LIST);

                resolve();

            }).catch((response) => {

                this.context.commit(Mutations.SET_ERROR, response);
                reject();

            });

        });

    };

    @Action
    [CustomersActions.UPDATE_RIGHTS](payload) {

        return new Promise<void>((resolve, reject) => {

            const {customerId, data} = payload;

            apiService.put(`customers/rights/${customerId}`, data).then(({data}) => {

                const _verified = apiService.verifyApiResult(data);
                if (!_verified) {
                    this.context.commit(Mutations.SET_ERROR, data);
                    return reject();
                }

                // refresh user //
                this.context.dispatch(CustomersActions.GET_ITEM, customerId);

                resolve();

            }).catch((response) => {

                this.context.commit(Mutations.SET_ERROR, response);
                reject();

            });

        });

    };

    @Action
    [CustomersActions.IMPORT_CUSTOMERS](payload) {

        return new Promise((resolve, reject) => {

            const {importer} = payload;

            apiService.put('customers/import/', importer).then(({data}) => {

                const _verified = apiService.verifyApiResult(data);
                if (!_verified) {
                    this.context.commit(Mutations.SET_ERROR, data);
                    return reject();
                }

                // refresh list in background//
                this.context.dispatch(CustomersActions.REFRESH_LIST);

                // resolve //
                resolve(data.report);

            }).catch((response) => {

                this.context.commit(Mutations.SET_ERROR, response);
                reject();

            });

        });

    };

    @Action
    [CustomersActions.DELETE_ITEM](editId) {

        return new Promise<void>((resolve, reject) => {

            apiService.delete(`customers/delete/${editId}`).then(({data}) => {

                const _verified = apiService.verifyApiResult(data);
                if (!_verified) {
                    this.context.commit(Mutations.SET_ERROR, data);
                    return reject();
                }

                // refresh list in background//
                this.context.dispatch(CustomersActions.REFRESH_LIST);

                resolve();

            }).catch((response) => {

                this.context.commit(Mutations.SET_ERROR, response);
                reject();

            });

        });

    };

    @Action
    [CustomersActions.UPDATE_ALLOCATIONS](payload) {

        return new Promise<void>((resolve, reject) => {

            const {editId, data} = payload;

            apiService.put(`customers/allocations/${editId}`, data).then(async ({data}) => {

                const _verified = apiService.verifyApiResult(data);
                if (!_verified) {
                    this.context.commit(Mutations.SET_ERROR, data);
                    return reject();
                }

                resolve();

            }).catch((response) => {

                this.context.commit(Mutations.SET_ERROR, response);
                reject();

            });

        });

    };

}
